import React from 'react';
import { Icon } from '../Icon/Icon';
import { ButtonProps, Variant } from './ButtonTypes';
import { ButtonStyling } from './StyledButton';

const EmptyButton = ({ className, children }: { className: any; children: any }) => (
  <button className={className}>{children}</button>
);

const StyledButton = ButtonStyling(EmptyButton);

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((buttonProps, ref) => {
  // const Button = ({ variant, label, isDisabled, iconComponent, iconName, isLoading, iconRight, other }: ButtonProps) => {
  const { variant, label, isDisabled, iconComponent, iconName, isLoading, iconRight, component, ...other } =
    buttonProps;

  const renderButtonContent = () => (
    <>
      {isLoading && (
        <>
          {/* Use sr-only to hide loading text from screen readers */}
          <span className='sr-only'>Loading...</span>
          <Icon name='spinner' spinnerColor='primary' ariaLabel='loading' size='large' />
        </>
      )}
      {!isLoading &&
        !iconRight &&
        (iconComponent || (iconName && <Icon ariaLabel={`${iconName} button icon`} name={iconName} />))}
      <span>{label}</span>
      {!isLoading &&
        iconRight &&
        (iconComponent || (iconName && <Icon ariaLabel={`${iconName} button icon`} name={iconName} />))}
    </>
  );

  if (component) {
    const StyledComponent = ButtonStyling(component);

    return (
      <StyledComponent com ref={ref} variant={variant} isDisabled={isDisabled}>
        {renderButtonContent()}
      </StyledComponent>
    );
  }

  return (
    <StyledButton com ref={ref} variant={variant} isDisabled={isDisabled}>
      {renderButtonContent()}
    </StyledButton>
  );
});
