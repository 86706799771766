import styled, { keyframes, css } from 'styled-components';
import { IconNames, IconSizes } from './IconTypes';
import { colors } from '../../theme';

export const iconImages: Record<IconNames, string> = {
  teladoc: new URL('../../assets/icons/teladoc.svg', import.meta.url).href,
  'radio-check-primary': new URL('../../assets/icons/ic_radio_check_primary.svg', import.meta.url).href,
  'circle-grey': new URL('../../assets/icons/ic_circle_grey.svg', import.meta.url).href,
  email: new URL('../../assets/icons/email.svg', import.meta.url).href,
  'profile-add': new URL('../../assets/icons/ic-profile-add.svg', import.meta.url).href,
  'profile-add-mint': new URL('../../assets/icons/ic-profile-add-mint.svg', import.meta.url).href,
  add: new URL('../../assets/icons/ic_add.svg', import.meta.url).href,
  'add-mint-circle-purple': new URL('../../assets/icons/ic_add-mint-circle-purple.svg', import.meta.url).href,
  search: new URL('../../assets/icons/ic_search_black_24px.svg', import.meta.url).href,
  'search-grey': new URL('../../assets/icons/ic_search_grey.svg', import.meta.url).href,
  'search-primary': new URL('../../assets/icons/ic_search_primary.svg', import.meta.url).href,
  'arrow-forward-green': new URL('../../assets/icons/ic_arrow_forward_green.svg', import.meta.url).href,
  'caret-down': new URL('../../assets/icons/ic_caret_down.svg', import.meta.url).href,
  signature: new URL('../../assets/icons/signature.svg', import.meta.url).href,
  'request-submitted': new URL('../../assets/icons/request-submitted.svg', import.meta.url).href,
  checkbox: new URL('../../assets/icons/ic_checkbox.svg', import.meta.url).href,
  dothealth: new URL('../../assets/icons/dothealth.svg', import.meta.url).href,
  'warning-circle-red': new URL('../../assets/icons/ic-warning-circle-red.svg', import.meta.url).href,
  'file-purple': new URL('../../assets/icons/ic-file-purple.svg', import.meta.url).href,
  'close-grey': new URL('../../assets/icons/ic-close-grey.svg', import.meta.url).href,
  'success-snackbar': new URL('../../assets/icons/ic-close-grey.svg', import.meta.url).href,
  'progress-snackbar': new URL('../../assets/icons/ic-close-grey.svg', import.meta.url).href,
  'error-snackbar': new URL('../../assets/icons/ic-close-grey.svg', import.meta.url).href,
  'check-purple': new URL('../../assets/icons/ic-check-purple.svg', import.meta.url).href,
  'check-circle-green': new URL('../../assets/icons/ic_check_circle_green.svg', import.meta.url).href,
  spinner: ''
};

export const size: Record<IconSizes, string> = {
  'x-small': '8px',
  small: '16px',
  regular: '20px',
  medium: '24px',
  large: '32px',
  'x-large': '50px',
  'xx-large': '120px'
};

const spinnerAnimation = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const StyledIcon = styled.i<{
  iconSize?: IconSizes;
  iconName: IconNames;
  spinnerColor?: keyof typeof colors;
}>`
  width: ${({ iconSize }) => (iconSize ? size[iconSize] : size.regular)};
  height: ${({ iconSize }) => (iconSize ? size[iconSize] : size.regular)};
  display: ${({ iconName }) => (iconName && iconName === 'spinner' ? 'block' : 'inline-block')};
  border-radius: ${({ iconName }) => (iconName && iconName === 'spinner' ? '50%' : 'initial')};
  border: ${({ iconName }) => (iconName && iconName === 'spinner' ? '0.25rem solid rgba(0, 0, 0, 0.2)' : 'initial')};
  border-top-color: ${({ spinnerColor, iconName }) =>
    spinnerColor && iconName && iconName === 'spinner' ? colors.primary : 'initial'};
  animation: ${({ iconName }) =>
    iconName && iconName === 'spinner'
      ? css`
          ${spinnerAnimation} 1s infinite linear
        `
      : 'initial'};
  content: '';
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: ${({ iconName }) => `url(${iconImages[iconName]})`};
`;
