const typography = {
  fontDefault: "'Avenir Next', sans-serif",

  bodyTextSize: '1.5rem',
  linkTextSize: '1.8rem',
  fontSizeLink: '1.6rem',
  fontSizeBody: '1.5rem',

  mobileFontSize: '1.8rem',
  desktopFontSize: '1.3rem',
  buttonFontSize: '1.3rem',

  fontSizeH1: '3.2rem',
  fontSizeH2: '2.6rem',
  fontSizeH3: '2.4rem',
  fontSizeH4: '2.2rem',
  fontSizeH5: '2rem',
  fontSizeH6: '1.8rem',

  fontSizeGiant: '3.2rem',
  fontSizeXXXLarge: '2.8rem',
  fontSizeXXLarge: '2.4rem',
  fontSizeXLarge: '3.2rem',
  fontSizeLarge: '2.8rem',
  fontSizeMedium: '1.5rem',
  fontSizeSmall: '2rem',
  fontSizeXSmall: '1.8rem',
  fontSizeXXSmall: '1.4rem',
  fontSizeXXXSmall: '1.2rem',
  fontSizeXXXXSmall: '1rem',
  textMaxWidth: '760px'
};

export default typography;
