const colors = {
  // Primary Colors
  purpleHeart: '#672bc2',
  lavender: '#9f69f0',
  turquoise: '#2effcd',
  persianRose: '#ef1ea4',

  // Status Colors
  success: '#14e4b2',
  warning: '#efc11e',
  error: '#ef1e2b',

  // Additional Colors
  turquoiseBlue: '#53e6d7',
  discoBlue: '#2ec8ff',
  neonBlue: '#5b53ff',
  neonPurple: '#c518f0',
  rose: '#f043ab',
  mandy: '#ef566c',
  orange: '#f09456',
  yellow: '#ffda00',
  greenYellow: '#b8eb2a',
  shamrock: '#56f094',
  forestGreen: '#0fad87',
  shipCove: '#8285a4',
  catskill: '#ebeefb',
  catskillWhite: '#f9faff',
  violetRed: '#f0568e',

  // Shades of Grey
  grey30: '#40374f',
  grey40: '#574f63',
  grey60: '#95919c',
  grey80: '#c4c5d2',
  grey90: '#e6e4eb',
  grey100: '#fafbff',
  white: '#fff',

  // Transparent Colors
  grey40Transparent: '#574f634c',

  // Theme Colors
  primary: '#672bc2',
  primaryLight: '#9f69f0',
  primaryContrast: '#fff',
  highlight: '#f9faff',
  background: '#fff',
  cardFill: '#f9faff',
  greyOutline: '#e6e4eb',
  header: '#40374f',
  headerSecondary: '#574f63',
  transparent: 'transparent',
  modalBackground: '#e6e4eb',

  // Box Shadows
  boxShadowCard: '0px 4px 11px rgba(18, 6, 35, 0.08)',
  boxShadowCardHover: '0 2px 28px rgba(9, 43, 62, 0.1), 0 10px 10px rgba(9, 43, 62, 0.08)',

  // Miscellaneous
  appBackground: '#f9faff',
  placeholderLight: '#4d574f63'
};

export default colors;
