import styled from 'styled-components';
import { colors, typography, variables, until } from '../../theme';
import { Variant } from './ButtonTypes';

export const ButtonStyling = (component: React.ComponentType<any>) => styled(component)<{
  variant: Variant;
  isDisabled?: boolean;
  iconRight?: boolean;
}>`
  font-family: ${(props) => (props?.theme?.fontFamily ? props?.theme?.fontFamily : typography.fontDefault)};
  background-color: ${({ variant, isDisabled }) => {
    if (isDisabled) {
      return `${colors.grey80} !important`;
    }

    switch (variant) {
      case 'primary':
        return colors.primary;
      case 'secondary':
        return colors.primaryContrast; // Replace with your secondary color variable
      case 'danger':
        return colors.mandy; // Replace with your danger color variable
      case 'linkButton':
        return colors.transparent; // Replace with your linkButton color variable
    }
  }};
  border: ${({ variant, isDisabled }) => {
    if (isDisabled) {
      return `2px solid ${colors.grey80} !important`;
    }

    switch (variant) {
      case 'primary':
        return `2px solid ${colors.primary}`;
      case 'secondary':
        return `2px solid ${colors.primary}`;
      case 'danger':
        return `2px solid ${colors.mandy}`;
      case 'linkButton':
        return 'none';
    }
  }};
  color: ${({ variant }) => {
    switch (variant) {
      case 'primary':
        return colors.white;
      case 'secondary':
        return colors.primary;
      case 'danger':
        return colors.white;
      case 'linkButton':
        return colors.grey40;
    }
  }};
  padding: ${({ variant }) => {
    switch (variant) {
      case 'linkButton':
        return '0';
      default:
        return '5px 24px';
    }
  }};
  text-align: ${({ variant }) => {
    switch (variant) {
      case 'linkButton':
        return 'left';
      default:
        return 'initial';
    }
  }};
  word-wrap: ${({ variant }) => {
    switch (variant) {
      case 'linkButton':
        return 'none';
      default:
        return 'initial';
    }
  }};
  width: ${({ variant }) => {
    switch (variant) {
      case 'linkButton':
        return 'auto';
      default:
        return 'initial';
    }
  }};
  height: ${({ variant }) => {
    switch (variant) {
      case 'linkButton':
        return 'auto';
      default:
        return 'initial';
    }
  }};
  min-width: ${({ variant }) => {
    switch (variant) {
      case 'linkButton':
        return '10px';
      default:
        return '100px';
    }
  }};
  transition: ${variables.transition};
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  line-height: 1.3;
  vertical-align: middle;
  font-weight: 400;
  font-size: ${typography.fontSizeBody};
  border-radius: 70px;
  height: 46px;
  width: auto;
  outline: none;
  cursor: pointer;
  transition: width 0.3s ease-in-out;

  &:hover:not(is-disabled):not(.is-disabled-opacity) {
    color: ${({ variant }) => {
      switch (variant) {
        case 'primary':
        case 'secondary':
        case 'danger':
          return colors.white;
        case 'linkButton':
          return colors.grey40Transparent;
      }
    }};
    background-color: ${({ variant }) => {
      switch (variant) {
        case 'primary':
          return colors.primaryLight;
        case 'secondary':
          return `#f0eaf9`;
        case 'danger':
          return colors.violetRed;
        case 'linkButton':
          return colors.transparent;
      }
    }};
    border: ${({ variant }) => {
      switch (variant) {
        case 'primary':
          return `2px solid ${colors.primaryLight}`;
        case 'danger':
          return `2px solid ${colors.violetRed}`;
      }
    }};
  }

  &:focus {
    background-color: ${({ variant }) => {
      switch (variant) {
        case 'primary':
          return colors.primaryLight;
        case 'secondary':
          return `#f0eaf9`;
        case 'danger':
          return colors.violetRed;
      }
    }};
  }

  &:active {
    background-color: ${({ variant }) => {
      switch (variant) {
        case 'primary':
          return `${colors.primary} !important`;
        case 'secondary':
          return `${colors.primaryContrast} !important`;
        case 'danger':
          return `${colors.mandy} !important`;
      }
    }};
    color: ${({ variant }) => {
      switch (variant) {
        case 'linkButton':
          return `${colors.grey40} !important`;
      }
    }};
  }

  @media screen and (max-width: ${variables.mobile}) {
    width: 100%;
  }

  & i {
    width: 16px;
    height: 16px;
    margin-right: ${({ iconRight }) => (iconRight ? '8px' : '8px')};
    margin-left: ${({ iconRight }) => (iconRight ? '8px' : '0px')};
  }

  // Screen Reader Styling for our buttons loading state
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
`;
