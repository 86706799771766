import { IconProps } from './IconTypes';
import { StyledIcon } from './StyledIcon';

export const Icon = ({ className, size, name, onClick, ariaLabel, ariaLabelledBy, ...other }: IconProps) => (
  <StyledIcon
    iconSize={size}
    iconName={name}
    role={onClick ? 'button' : ''}
    onClick={onClick}
    aria-label={ariaLabel}
    title={ariaLabel}
    aria-labelledby={ariaLabelledBy}
    {...other}
  />
);
