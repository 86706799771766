const variables = {
  /* padding */
  profileImageLeftMargin: '20px',
  pagePaddingTop: '64px',
  elementPaddingX: '4px',
  elementMargin: '12px',

  groupPaddingX: '16px',
  groupPaddingY: '16px',
  sectionPaddingX: '32px',

  /* z-index */
  zIndexModal: 2,
  zIndexNav: 1,

  /* dimensions */
  profileImageHeight: '384px',
  borderRadius: '16px',
  borderRadiusRounded: '290486px',
  bannerHeightDesktop: '64px',

  gap: '32px',
  minColWidth: '50px',

  mini: '280px',
  mobile: '375px',
  mobileLarge: '420px',
  tablet: '769px',
  desktop: 'calc(960px + calc(2 * var(--gap)))',
  widescreen: 'calc(1152px + calc(2 * var(--gap)))',
  fullhd: 'calc(1344px + calc(2 * var(--gap)))',

  speed: '86ms',
  transition: 'all 0.2s ease-in-out'
};

export default variables;
